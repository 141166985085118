<template>
  <el-container class="home-cantainer">
    <!-- 头 -->
    <el-header class="headerfix" style="    width: 100%;">
      <div>
        <a href="/" class="small-image">
          <img src="../assets/company.jpg" alt="" style="width:100%" />
        </a>
        <div style="display:flex;flex-direction: column;font-size:15px;">
          <span>Call Us 24/7/365</span>
          <div style="margin-left: 20px;">
            <el-link type="primary">626-206-2952</el-link>
          </div>
        </div>
      </div>
      <el-menu :default-active="activeIndex"
          class="el-menu-demo" mode="horizontal" 
          @select="handleSelect" 
          background-color="#545c64"
          text-color="#fff"
          style="display:flex"
          active-text-color="#ffd04b"
          router>
          <!-- <el-menu-item index="welcome">UNION</el-menu-item> -->
          
        <el-submenu index="1">
          <template slot="title">LOGISTICS SERVICES</template>
          <el-menu-item index="FullTruckload"><i class="el-icon-location"></i>Full Truckload</el-menu-item>
          <el-menu-item index="LTT"><i class="el-icon-location"></i>Less Than Truckload</el-menu-item>
          <el-menu-item index="Intermodal"><i class="el-icon-location"></i>Intermodal</el-menu-item>
          <el-menu-item index="Drayage"><i class="el-icon-location"></i>Drayage</el-menu-item>
          <el-menu-item index="AirFreight"><i class="el-icon-location"></i>Air Freight</el-menu-item>
          <el-menu-item index="SeaFreight"><i class="el-icon-location"></i>Sea Freight</el-menu-item>
          </el-submenu>
        <el-submenu index="2">
          <template slot="title">SPECIALIZED SERVICES</template>
          <el-menu-item index="OOCargo"><i class="el-icon-location"></i>Oversize-Overweight Cargo</el-menu-item>
          <el-menu-item index="WareHousing"><i class="el-icon-location"></i>Overseas WareHousing</el-menu-item>
          <el-menu-item index="Customs"><i class="el-icon-location"></i>Customs & Compliance</el-menu-item>
          <el-menu-item index="LastMile"><i class="el-icon-location"></i>Last Mile</el-menu-item>
        </el-submenu>
        
        <el-menu-item index="ABOUT_US"><a target="_blank">ABOUT_US</a></el-menu-item>
        <el-menu-item >
          <el-button  type="medium" class="el-icon-search" v-if="!show" @click="toggleSearch">search</el-button>
          </el-menu-item>
        <el-menu-item >
          <el-input
            placeholder="please input"
            v-if="show"
            ref ="inputRef"
            @blur="handleBlur"
            v-model="searchvalue">
            <el-button slot="append" icon="el-icon-search" @click.stop="startsearch"></el-button>
          </el-input>
        </el-menu-item >
      </el-menu>
      <div class="line"></div>
      <!-- <el-button class="button-item" type="danger" @click="restt">exit</el-button> -->
    </el-header>

    <!-- 主lan -->
    <el-container>
      <el-main>
        
        <router-view></router-view>
      </el-main>
    </el-container>
    <!-- 下层 -->
    <el-footer style="height:auto;display: flex;flex-direction: row;padding: 0px;justify-content: center;  padding-top: 40px;padding-bottom: 20px;">
      <div class="my-div" style="float: left;"> OUR MODES 
        <div class="my-div1" > 
          <el-link type="primary" href="#/FullTruckload">Full Truckload </el-link><br>
          <el-link type="primary" href="#/LTT">Less Than Truckload </el-link><br>
          <el-link type="primary" href="#/Intermodal">Intermodal </el-link><br>
          <el-link type="primary" href="#/Drayage">Drayage </el-link><br>
          <el-link type="primary" href="#/SeaFreight">Sea Freight </el-link><br>
          <el-link type="primary" href="#/AirFreight">Air Freight </el-link><br>
      </div>
      </div>
      <div class="my-div" style="float: left;"> SPECIALIZED SERVICES 
        <div class="my-div1" > 
          <el-link type="primary" href="#/OOCargo">Oversize-Overweight Cargo</el-link><br>
          <el-link type="primary" href="#/WareHousing">Overseas WareHousing </el-link><br>
          <el-link type="primary" href="#/Customs">Customs & Compliance </el-link><br>
          <el-link type="primary" href="#/LastMile">Last Mile </el-link><br>
      </div>
      </div>
      <div class="my-div2" style="text-align: center;"> CONTACT US 
        <div class="my-div1" style="display:flex;"> 
          <img src="../assets/company.jpg" alt="" style="width: 150px;height:150px;"/>
          <div style="text-align: left;margin-left: 20px;">
          <br>
       <span style="color:white;font-size:20px;display: flex;place-content: center;">Union Worldwide Logistic </span><br>
       <div style="display: flex;">Address: <el-link type="primary" >18351 Colima Rd, Rowland Heights, CA 91748</el-link><br></div>
       Email: <el-link type="primary">truck@u-worldwide.com</el-link><br>
       TEL: <el-link type="primary">626-206-2952</el-link>
        </div>
        </div>
      </div>
    </el-footer>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      iscollapse: false,
      //头部参数
      activeIndex: '1',
      activeIndex2: '1',
      searchvalue:'',
      show:false,
      executeBlur: true, // 标志位，用于控制是否执行 blur 事件
    };
  },
  methods: {
    //t头部函数
     handleSelect(key, keyPath) {
        console.log(key, keyPath);
      },
    restt() {
      //清除token
      window.sessionStorage.clear();
      //跳转页面
      this.$router.push("/Login");
    },
    inorout() {
      this.iscollapse = !this.iscollapse;
    },
    handleBlur(event) {
      event.stopPropagation()
      if (this.executeBlur) {
        // 执行 blur 事件的操作
        this.executeBlur = false;
        this.inputRemove(event);
      } else {
        // 不执行 blur 事件的操作
        this.executeBlur = true; // 重置标志位，以便下次正常执行 blur 事件
      }
    },
    inputRemove(event){
      event.stopPropagation()
      if(!this.executeBlur){
        this.show = false
      }
    },
    toggleSearch(event){
      event.stopPropagation()
      this.show = true
      this.$nextTick(()=>{
        this.$refs.inputRef.focus()
      })
    },
    startsearch(event){
      event.stopPropagation()
      this.executeBlur = false; // 设置标志位为 false，阻止 blur 事件的执行
      this.$message.info("No results found")
    },
    
  },
};
</script>

<style lang="less" scoped>
img{  
  width: auto;  
  height: auto;  
  max-width: 100%;  
  max-height: 100%;     
} 
// 底部
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-footer {
  // background-color: #ccc;
  background: linear-gradient(to bottom,#141515 0%,#0a3043 100%);
  height: auto;
}
// 底部的div 
.my-div {
  color: white;
    background: linear-gradient(to bottom,#141515 0%,#0a3043 100%);
		padding:15px 40px;
    font-size:25px;
    border-right: inset;
}
.my-div1 {
  color: #569ebf;
  line-height: 22px;
  background: linear-gradient(to bottom,#141515 0%,#0a3043 100%);
  font-size:15px;
  width: 100%;
}
// 底部的div 
.my-div2 {
  color: white;
    background: linear-gradient(to bottom,#141515 0%,#0a3043 100%);
  	padding:15px 40px;
    font-size:25px;
		width: 33%;
}
.home-cantainer {
  height: 100%;
}
.el-header {
  background-color: #545c64;
  display: flex;
  justify-content: space-between;
  padding-left: center;
  align-items: center;
  color: #fff;
  font-size: 20px;
  > div {
    display: flex;
    align-items: center;
    span {
      margin-left: 20px;
    }
  }
  .small-image {
    width: 20%;
    height: 12%;
  }
}
.button-item {
  margin-right: 10px;
}
.el-aside {
  background-color: #303133;
}
.el-main {
  background: white;;
  padding:0px;
  overflow: hidden;
}
.inout-botton {
  background-color: #373d41;
  font-size: 10px;
  line-height: 24px;
  color: #fff;
  text-align: center;
}
.el-row {
    margin-bottom: 20px;
    align-items: center;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
    padding-left: center;
    align-items: center;
  }
  .bg-purple-dark {
    background: #131414;
  }
  .bg-purple {
    background: #151616;
  }
  .bg-purple-light {
    background: #0e0e0f;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #151517;
  }
  .headerfix{
        height: 60px;
    position: fixed;
    top: 0px;
    z-index: 1000;
    overflow: hidden;
    width: 100%;
  }
  .el-link.el-link--primary {
    color: #9cd5ef;
}
.el-header > div{
  width:100%;
}
</style>
