<template>
  <div>
    <!-- 一些图片 -->
    <div id="myDiv">
        <div class="carousel-wrapper">
        <el-carousel height="500px" >
          <el-carousel-item v-for="item in carouseData" :key="item.index">
            <div class="carousel-item" :style="{ backgroundImage: `url(${item.url})` }">
              <div class="container">
                <div class="text-block text-center" style="width:700px;text-align:center;z-index:20">
                  <!-- <h1 class="h2 text-uppercase" style="font-size: 55px;line-height: 58px;"><small>WORK WITH THE</small><br>
                  <span style="font-size:70px;">BEST</span></h1> -->
                  <p style="font-size:25px;font-weight:700;">Our mission is to always put our customers at the source of our priority list by providing excellent, consistent service with the best teamwork and passion in the transportation industry.</p>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

  <div style="background-image:url('https://www.tql.com/getmedia/a1a515fa-460e-41e6-8e8a-b0c5a638b4ac/bg-our-modes.jpg');display: flex;justify-content: center;">
    <div style="width:100%;flex-direction: column;display: flex;align-items: center;justify-content: center;max-width: 1040px;">
      <div style="color:white;font-size:40px;padding:20px;">
        OUR MODES
      </div>
      <div style="width: 100%;margin-bottom: 20px;">
        <el-row :gutter="20" style="width: 100%;">
          <el-col :span="8"><div class="mode-content">
            <a href="/full-truckload"><img class="d-block d-md-inline mx-auto mr-md-3" src="../assets/image/FullTruckload.png" alt="Full Truckload"></a>
            <el-link type="primary" href="#/FullTruckload">Full Truckload</el-link>
          </div></el-col>
          <el-col :span="8">
            <div class="mode-content">
              <a href="/full-truckload"><img class="d-block d-md-inline mx-auto mr-md-3" src="../assets/image/LTL.png" alt="Full Truckload"></a>
              <el-link type="primary" href="#/LTT">Less Than Truckload</el-link>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="mode-content">
              <a href="/full-truckload"><img class="d-block d-md-inline mx-auto mr-md-3" src="../assets/image/Intermodal.png" alt="Full Truckload"></a>
              <el-link type="primary" href="#/Intermodal">Intermodal</el-link>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="mode-content">
              <a href="/full-truckload"><img class="d-block d-md-inline mx-auto mr-md-3" src="../assets/image/Drayage.png" alt="Full Truckload"></a>
              <el-link type="primary" href="#/Drayage">Drayage</el-link>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="mode-content">
              <a href="/full-truckload"><img class="d-block d-md-inline mx-auto mr-md-3" src="../assets/image/Air.png" alt="Full Truckload"></a>
              <el-link  type="primary" href="#/AirFreight">Air Freight</el-link>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="mode-content">
              <a href="/full-truckload"><img class="d-block d-md-inline mx-auto mr-md-3" src="../assets/image/Ocean.png" alt="Full Truckload"></a>
              <el-link type="primary" href="#/SeaFreight">Sea Freight</el-link>
            </div>
          </el-col>          
        </el-row>


      </div>
    </div>
  </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      //定义跑马灯的图片路径
      carouseData: [
        { url: require("../assets/son_img/Truckload.jpg") },
        { url: require("../assets/son_img/LTT.png") },
        { url: require("../assets/son_img/Intermodal.png") },
        { url: require("../assets/son_img/Drayage.png") },
      ],
    };
  },


}
</script>

<style scoped >
.ziti{
  width: 100%;
  font-size: 30px;
  font-family: emoji;
  font-weight: 700;
  color: black;
}
/* 图片 */

img{  
    width: auto;  
    height: auto;  
    max-width: 100%;  
    max-height: 100%;     
} 
/* 图片的设置 */
.carousel-img {
  display: block; /* 清除默认的inline元素特性 */
  width: 100%; /* 设置图片宽度为100% */
  height: auto; /* 高度自适应 */
}
#myDiv {
  width: 100%; /* 设置元素宽度为100%，占据一整行 */
  display: flex;
  justify-content: center;
  background-color: #f0f0f0; /* 用于示例的背景色 */
}
#myDiv2 {
  width: auto;; /* 设置元素宽度为100%，占据一整行 */
  height: auto;
  /* display: flex; */
  overflow: auto;
  word-break: break-all;
  padding:20px;
  justify-content: center;
  background-color: #f0f0f0; /* 用于示例的背景色 */
}
#content {
  text-align: center;
}
/* 图片展示 */
.el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }
  
  .el-carousel__item:nth-child(2n) .div-item{
    background-size: cover;
    background-position: center;
    background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) .div-item{
    background-size: cover;
    background-position: center;
    background-color: #d3dce6;
  }
.carousel {
  width: 100%; /* 设置元素宽度为100%，占据一整行 */
}

.el-carousel__container{
  width: 100%;
  height: 400px;
}
.carousel-wrapper{
  width: 100%;
}
.rightulliimg {
  width: 100%;
  height: auto;
}
.textfont{
  font-family: emoji;
  text-indent: 2em;
  line-height: 1.5;
  font-size: 18px;
  color: #7c7c7c;
}
.mode-content{
    display: flex;
    align-items: center;
}
.mode-content a{
  color:white;
  margin: 25px 10px;
  font-weight: 700;
  font-size: 20px;
}
.carousel-item{
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}
.container{
    max-width: 1680px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    height: 100%;
    align-items: center;
    color: white;
}

.container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-color: rgba(119, 119, 119, 0.5); /* 斜区域的背景色 */
  transform-origin: bottom left;
  transform: skew(25deg); /* 控制斜区域的角度 */
}
.h1{
  font-size: 55px;
  line-height: 58px;
  margin: 0 -70px 4px;
}
</style>